import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./routes/Main";
import Invitation from "./routes/Invitation";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="App">
      <Fragment>
        <Router>
          <Routes>
            <Route path="/stanlydessy" element={<Invitation />} />
            <Route path="/stanlydessy/:guestName" element={<Invitation />} />
            <Route path="/" element={<Main />} />
          </Routes>
        </Router>
      </Fragment>
    </div>
  );
}

export default App;
