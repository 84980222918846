import React, { Fragment, useRef, useState } from "react";
import { Button, Card, Divider, Flex } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { useSpring, animated } from "@react-spring/web";
import { useIntersectionObserver } from "./Util";
import { JackInTheBox, Roll } from "react-awesome-reveal";
require("../style/countdown.css");

export default function Countdown() {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const triggerRef = useRef<any>();
  const triggerRef2 = useRef<any>();
  const dataRef = useIntersectionObserver(triggerRef, {
    freezeOnceVisible: true,
  });
  const dataRef2 = useIntersectionObserver(triggerRef2, {
    freezeOnceVisible: true,
  });

  var countDownDate = new Date("Jan 19, 2025 19:00:00").getTime();
  var x = setInterval(function () {
    var now = new Date().getTime();
    var distance = countDownDate - now;

    setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
    setSeconds(Math.floor((distance % (1000 * 60)) / 1000));

    if (distance < 0) {
      clearInterval(x);
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    }
  }, 100);

  const { numberDays } = useSpring<any>({
    from: {
      numberDays: dataRef2?.isIntersecting ? 0 : days,
    },
    to: {
      numberDays: dataRef2?.isIntersecting ? days : 0,
    },
    numberDays: days,
    config: { duration: 1000 },
  });
  const { numberHours } = useSpring<any>({
    from: {
      numberHours: dataRef2?.isIntersecting ? 0 : hours,
    },
    to: {
      numberHours: dataRef2?.isIntersecting ? hours : 0,
    },
    numberHours: hours,
    config: { duration: 1000 },
  });
  const { numberMinutes } = useSpring<any>({
    from: {
      numberMinutes: dataRef2?.isIntersecting ? 0 : minutes,
    },
    to: {
      numberMinutes: dataRef2?.isIntersecting ? minutes : 0,
    },
    numberMinutes: minutes,
    config: { duration: 1000 },
  });
  const { numberSeconds } = useSpring<any>({
    from: {
      numberSeconds: dataRef2?.isIntersecting
        ? 0
        : seconds > 10
          ? seconds - 10
          : seconds,
    },
    to: {
      numberSeconds: dataRef2?.isIntersecting
        ? seconds > 10
          ? seconds - 10
          : seconds
        : 0,
    },
    numberSeconds: seconds,
    config: { duration: 1000 },
  });

  const { numberDate } = useSpring<any>({
    from: { numberDate: dataRef?.isIntersecting ? 0 : 19 },
    to: { numberDate: dataRef?.isIntersecting ? 19 : 0 },
    delay: 100,
    config: { duration: 1000 },
  });
  const { numberMonth } = useSpring<any>({
    from: { numberMonth: dataRef?.isIntersecting ? 0 : 1 },
    to: { numberMonth: dataRef?.isIntersecting ? 1 : 0 },
    delay: 100,
    config: { duration: 1000 },
  });
  const { numberYear } = useSpring<any>({
    from: { numberYear: dataRef?.isIntersecting ? 0 : 25 },
    to: { numberYear: dataRef?.isIntersecting ? 25 : 0 },
    delay: 100,
    config: { duration: 1000 },
  });

  const bgImage = "url(../images/Stanly_Dessy_100.jpg)";

  return (
    <Fragment>
      <div
        style={{
          backgroundImage: bgImage,
          height: "100%",
          backgroundPosition: "center bottom",
          backgroundSize: "cover",
          color: "white",
          padding: "20px",
          width: "100%",
        }}
      >
        <Flex
          ref={triggerRef}
          className="fullWidth marginTopExtraLarge"
          align="start"
          justify="flex-start"
          vertical
        >
          <Roll>
            <p className="noMarginPadding fontForum fontLarge">SAVE</p>
            <p className="noMarginPadding fontForum fontLarge">THE DATE</p>
          </Roll>
          <p className="noMarginPadding fontForum fontExtraLarge">
            <animated.span>
              {numberDate.to((n: any) => n.toFixed())}
            </animated.span>
          </p>

          <p className="noMarginPadding fontForum fontExtraLarge">
            0
            <animated.span>
              {numberMonth.to((n: any) => n.toFixed(0))}
            </animated.span>
          </p>

          <p className="noMarginPadding fontForum fontExtraLarge">
            <animated.span>
              {numberYear.to((n: any) => n.toFixed(0))}
            </animated.span>
          </p>

          <Button
            icon={<CalendarOutlined />}
            target={"_blank"}
            href={
              "https://calendar.google.com/calendar/render?action=TEMPLATE&text=Stanly+Dessy+Wedding&dates=20250119T190000/20250119T210000"
            }
          >
            Remind me
          </Button>
        </Flex>
        <Divider />
        <Flex
          ref={triggerRef2}
          className="fullWidth countdownRow"
          align="center"
          justify="space-evenly"
        >
          <Card className="countdownCard" styles={{ body: { padding: "5px" } }}>
            <JackInTheBox>
              <p className="countdown">
                <animated.span>
                  {numberDays.to((n: any) => n.toFixed(0))}
                </animated.span>
              </p>
              <p className="countdownSuffix">Days</p>
            </JackInTheBox>
          </Card>
          <Card className="countdownCard" styles={{ body: { padding: "5px" } }}>
            <JackInTheBox>
              <p className="countdown">
                <animated.span>
                  {numberHours.to((n: any) => n.toFixed(0))}
                </animated.span>
              </p>
              <p className="countdownSuffix">Hours</p>
            </JackInTheBox>
          </Card>
          <Card className="countdownCard" styles={{ body: { padding: "5px" } }}>
            <JackInTheBox>
              <p className="countdown">
                <animated.span>
                  {numberMinutes.to((n: any) => n.toFixed(0))}
                </animated.span>
              </p>
              <p className="countdownSuffix">Minutes</p>
            </JackInTheBox>
          </Card>
          <Card className="countdownCard" styles={{ body: { padding: "5px" } }}>
            <JackInTheBox>
              <p className="countdown">
                <animated.span>
                  {numberSeconds.to((n: any) => n.toFixed(0))}
                </animated.span>
              </p>
              <p className="countdownSuffix">Seconds</p>
            </JackInTheBox>
          </Card>
        </Flex>
      </div>
      <Divider />
    </Fragment>
  );
}
